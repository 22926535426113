.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 30px;
}

.logo1,
.logo2,
.logo3,
.logo4 {
  height: 30px;
}

.logoContainer1,
.logoContainer2,
.logoContainer3,
.logoContainer4 {
  position: absolute;
  opacity: 0;
  width: 125px;
  text-decoration: none;
}

.logoContainer1 {
  margin-left: -5%;
  -webkit-animation: slide 0.4s forwards;
  -webkit-animation-delay: 0.6s;
  animation: slide 0.4s forwards;
  animation-delay: 0.6s;
}

.logoContainer2 {
  margin-left: -0%;
  -webkit-animation: slide2 0.42s forwards;
  -webkit-animation-delay: 0.65s;
  animation: slide2 0.42s forwards;
  animation-delay: 0.65s;
}

.logoContainer3 {
  margin-left: 5%;
  -webkit-animation: slide3 0.45s forwards;
  -webkit-animation-delay: 0.7s;
  animation: slide3 0.45s forwards;
  animation-delay: 0.7s;
}

.logoContainer4 {
  margin-left: 10%;
  -webkit-animation: slide4 0.47s forwards;
  -webkit-animation-delay: 0.75s;
  animation: slide4 0.47s forwards;
  animation-delay: 0.75s;
}

.logoTextContainer1,
.logoTextContainer2,
.logoTextContainer3,
.logoTextContainer4 {
  display: flex;
  align-items: center;
  flex-direction: row;
  text-decoration: none;
  width: 0px;
}

.logoText1,
.logoText2,
.logoText3,
.logoText4 {
  font-family: Calibri;
  color: white;
  opacity: 0%;
  margin-left: -25%;
}

.logoText1:hover,
.logoText2:hover,
.logoText3:hover,
.logoText4:hover {
  color: grey;
}

.logoContainer1:hover .logoText1,
.logoContainer2:hover .logoText2,
.logoContainer3:hover .logoText3,
.logoContainer4:hover .logoText4 {
  -webkit-animation: showLogo 0.4s forwards;
  animation: showLogo 0.4s forwards;
}

@-o-keyframes slide {
  100% {
    margin-left: 0%;
    opacity: 1;
  }
}

@-ms-keyframes slide {
  100% {
    margin-left: 0%;
    opacity: 1;
  }
}

@-moz-keyframes slide {
  100% {
    margin-left: 0%;
    opacity: 1;
  }
}

@-webkit-keyframes slide {
  100% {
    margin-left: 0%;
    opacity: 1;
  }
}

@keyframes slide {
  100% {
    margin-left: 0%;
    opacity: 1;
  }
}

@-webkit-keyframes showLogo {
  100% {
    margin-left: 5px;
    opacity: 1;
  }
}

@keyframes showLogo {
  100% {
    margin-left: 10px;
    opacity: 1;
  }
}

@-webkit-keyframes showLogoWidth {
  100% {
    margin-left: 5px;
    width: 200px;
  }
}

@keyframes showLogoWidth {
  100% {
    margin-left: 10px;
    width: 200px;
  }
}

@-webkit-keyframes slide2 {
  100% {
    margin-left: 125px;
    opacity: 1;
  }
}

@keyframes slide2 {
  100% {
    margin-left: 125px;
    opacity: 1;
  }
}

@-webkit-keyframes slide3 {
  100% {
    margin-left: 250px;
    opacity: 1;
  }
}

@keyframes slide3 {
  100% {
    margin-left: 250px;
    opacity: 1;
  }
}

@-webkit-keyframes slide4 {
  100% {
    margin-left: 375px;
    opacity: 1;
  }
}

@keyframes slide4 {
  100% {
    margin-left: 375px;
    opacity: 1;
  }
}
