.MainContainer {
  display: flex;
  width: 100vw;
  height: 100vh;
  min-height: 800px;
  min-width: 1300px;
}

.BodyContainer {
  position: static;
  display: flex;
  margin-top: 5%;
  padding-top: 5%;
  justify-content: center;
  overflow-y: scroll;
  height: 100vh;
  width: 100%;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.BodyContainer::-webkit-scrollbar {
  display: none;
}

.SubBodyContainer1 {
  display: flex;
  width: 100%;
  justify-content: center;
}

.SubBodyContainer {
  position: static;
  display: flex;
  flex-direction: column;
  width: 800px;
}

.PictureContainer {
  position: fixed;
  margin-top: 20px;
  margin-right: 1050px;
  width: 180px;
  height: 180px;
}

.Picture {
  height: 180px;
  border-radius: 90px;
}

.Title {
  position: static;
  margin-left: -5%;
  -webkit-animation: slide 0.5s forwards;
  animation: slide 0.5s forwards;
}

.Wave {
  height: 80px;
  margin-right: 20px;
  margin-left: 10%;
}

.Degree {
  height: 80px;
}

.Iphone {
  height: 80px;
}

.Hiking {
  height: 80px;
}

.Camera {
  height: 80px;
}

.Contact {
  height: 80px;
}

.Down {
  height: 20px;
  -webkit-animation: bounce 1s infinite alternate;
  animation: bounce 1s infinite alternate;
}

.DownHidden {
  margin-top: 1%;
  position: fixed;
  height: 20px;
}

.FreeTimeIcons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.SantaCruzLink,
.ExhibitULink,
.CameraLink {
  color: rgb(0, 224, 231);
}

.SantaCruzLink:visited,
.ExhibitULink:visited,
.CameraLink:visited {
  color: rgb(0, 186, 192);
}

.SkillsAndTechnologyContainer {
  position: static;
  display: flex;
  flex-direction: row;
}

.SkillIconTextContainer {
  position: static;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.SkillText {
  position: static;
  margin-left: 10px;
}

.SkillContainer {
  position: static;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.SkillLists {
  position: static;
  display: flex;
  flex-direction: row;
}

.Seperator1 {
  margin-top: 100px;
}

.Seperator {
  margin-top: 800px;
}

.LastSeperator {
  margin-top: 600px;
}

.Paragraph1Hidden,
.Paragraph2Hidden,
.Paragraph3Hidden,
.Paragraph4Hidden,
.Paragraph5Hidden,
.Paragraph6Hidden {
  position: static;
  color: white;
  opacity: 0;
  width: 100%;
  min-width: 600px;
  text-align: center;
}

.Paragraph1Hidden {
  font-size: 32px;
  font-weight: 400;
  text-align: center;
}

.Paragraph2Hidden {
  font-size: 28px;
}

.Paragraph3Hidden {
  font-size: 28px;
}

.Paragraph4Hidden {
  font-size: 28px;
}

.Paragraph5Hidden {
  font-size: 28px;
}

.Paragraph6Hidden {
  font-size: 28px;
}

.ScrollMoreHidden {
  position: static;
  opacity: 0;
  color: white;
  font-family: Calibri;
  width: 100%;
  font-size: 28px;
  font-weight: 300;
  min-width: 600px;
  margin-top: 200px;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.Paragraph1,
.Paragraph2,
.Paragraph3,
.Paragraph4,
.Paragraph5,
.Paragraph6 {
  position: static;
  opacity: 0;
  color: white;
  font-family: Calibri;
  margin-left: -20%;
  width: 100%;
  font-size: 26px;
  font-weight: 300;
  min-width: 600px;
  -webkit-animation: slide 0.5s forwards;
  animation: slide 0.5s forwards;
}

.Paragraph1 {
  display: flex;
  font-size: 32px;
  min-width: 600px;
  text-align: center;
  justify-content: center;
  align-items: center;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.ScrollMore {
  position: static;
  opacity: 0;
  color: white;
  font-family: Calibri;
  margin-left: -20%;
  width: 100%;
  font-size: 26px;
  font-weight: 300;
  min-width: 600px;
  margin-top: 200px;
  -webkit-animation: slide 0.5s forwards;
  animation: slide 0.5s forwards;
  -webkit-animation-delay: 1.4s;
  animation-delay: 1.4s;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.Paragraph4 {
  font-size: 24px;
}

.HeaderContainer {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 97%;
  height: 20px;
  margin-top: 30px;
}

.HeaderPicturePhoneOnly {
  position: fixed;
  height: 0px;
  width: 0px;
}

.NavContainer {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.Header {
  width: 600px;
}

@media only screen and (max-width: 500px) {
  .BodyContainer {
    position: static;
    display: flex;
    margin-top: 0%;
    height: 900px;
    width: 100vw;
    justify-content: center;
  }

  .SubBodyContainer {
    overflow-y: scroll;
    position: static;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    width: 100%;
    padding: 10px;
  }

  .Seperator1 {
    margin-top: 60px;
  }

  .PictureContainer {
    position: absolute;
    left: 0px;
    top: 60px;
    width: 40px;
    height: 40px;
  }

  .Picture {
    height: 0px;
    width: 0px;
  }

  .Paragraph1Hidden,
  .Paragraph2Hidden,
  .Paragraph3Hidden,
  .Paragraph4Hidden,
  .Paragraph5Hidden,
  .Paragraph6Hidden {
    position: static;
    opacity: 0;
    width: 100vw;
    min-width: 300px;
    text-align: center;
  }

  .Paragraph1Hidden {
    font-size: 32px;
    font-weight: 400;
    text-align: center;
  }

  .Paragraph2Hidden {
    font-size: 28px;
  }

  .Paragraph3Hidden {
    font-size: 28px;
  }

  .Paragraph4Hidden {
    font-size: 28px;
  }

  .Paragraph5Hidden {
    font-size: 28px;
  }

  .Paragraph6Hidden {
    font-size: 28px;
  }

  .Paragraph1,
  .Paragraph2,
  .Paragraph3,
  .Paragraph4,
  .Paragraph5,
  .Paragraph6 {
    position: static;
    opacity: 0;
    padding-right: 2%;
    color: white;
    font-family: Calibri;
    font-size: 18px;
    font-weight: 300;
    min-width: 0px;
    width: 98%;
    -webkit-animation: slide 0.5s forwards;
    animation: slide 0.5s forwards;
  }

  .Paragraph1 {
    display: flex;
    font-size: 22px;
    min-width: 0px;
    width: 98%;
    text-align: center;
    justify-content: center;
    align-items: center;
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
  }

  .ScrollMore {
    position: static;
    opacity: 0;
    color: white;
    font-family: Calibri;
    margin-left: -20%;
    width: 100%;
    font-size: 22px;
    font-weight: 300;
    min-width: 0px;
    margin-top: 200px;
    -webkit-animation: slide 0.5s forwards;
    animation: slide 0.5s forwards;
    -webkit-animation-delay: 1.4s;
    animation-delay: 1.4s;
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  .Title {
    position: static;
    margin-left: -5%;
    -webkit-animation: slide 0.5s forwards;
    animation: slide 0.5s forwards;
  }

  .Wave {
    height: 60px;
    margin-right: 20px;
    margin-left: 10%;
  }

  .Degree {
    height: 60px;
  }

  .Iphone {
    height: 60px;
  }

  .Hiking {
    height: 60px;
  }

  .Camera {
    height: 60px;
  }

  .Contact {
    height: 60px;
  }

  .Down {
    height: 20px;
    -webkit-animation: bounce 1s infinite alternate;
    animation: bounce 1s infinite alternate;
  }

  .SkillsAndTechnologyContainer {
    position: static;
    display: flex;
    flex-direction: column;
  }

  .Paragraph4 {
    font-size: 16px;
  }

  .HeaderContainer {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .Header {
    display: flex;
    align-items: center;
  }

  .HeaderPicturePhoneOnly {
    position: absolute;
    margin-left: 10px;
    height: 40px;
    width: 40px;
    border-radius: 20px;
  }
}

@-o-keyframes slide {
  100% {
    margin-left: 2%;
    opacity: 1;
  }
}

@-ms-keyframes slide {
  100% {
    margin-left: 2%;
    opacity: 1;
  }
}

@-moz-keyframes slide {
  100% {
    margin-left: 2%;
    opacity: 1;
  }
}

@-webkit-keyframes slide {
  100% {
    margin-left: 2%;
    opacity: 1;
  }
}

@keyframes slide {
  100% {
    margin-left: 2%;
    opacity: 1;
  }
}

@-o-keyframes bounce {
  0% {
    margin-top: 1%;
  }
  100% {
    margin-top: 3%;
  }
}

@-ms-keyframes bounce {
  0% {
    margin-top: 1%;
  }
  100% {
    margin-top: 3%;
  }
}

@-moz-keyframes bounce {
  0% {
    margin-top: 1%;
  }
  100% {
    margin-top: 3%;
  }
}

@-webkit-keyframes bounce {
  0% {
    margin-top: 1%;
  }
  100% {
    margin-top: 3%;
  }
}

@keyframes bounce {
  0% {
    margin-top: 1%;
  }
  100% {
    margin-top: 3%;
  }
}
