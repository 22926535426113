.MainContainer {
  width: 100vw;
  height: 100vh;
  min-height: 600px;
  min-width: 600px;
}

.BodyContainer {
  display: flex;
  height: 80%;
  justify-content: center;
  margin-left: 20%;
  flex-direction: column;
}

.NameContainer {
  display: flex;
  height: 250px;
  width: 100%;
}

.Title {
  text-align: center;
  margin-top: 60px;
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.ShortIntro {
  margin-top:100px;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.Email {
  margin-top:180px;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.HeaderContainer {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 97%;
  height: 20px;
  margin-top: 30px;
}

.HeaderPicturePhoneOnly {
  position: fixed;
  height: 0px;
  width: 0px;
}

.NavContainer {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.Header {
  width: 600px;
}

.FooterContainer {
  width: 500px;
}

@media (max-width: 800px) {
  .BodyContainer {
    height: 60%;
    margin-left: 2%;
  }

  .NameContainer {
    display: flex;
    height: 250px;
    width: 400px;
  }

  .HeaderContainer {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .Header {
    display: flex;
    align-items: center;
  }


  .HeaderPicturePhoneOnly {
    position: absolute;
    margin-left: 10px;
    height: 40px;
    width: 40px;
    border-radius: 20px;
  }
}
