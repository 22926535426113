.pictureContainer {
  margin-left: -5%;
  opacity: 0;
  -webkit-animation: slide 0.4s forwards;
  -webkit-animation-delay: 0.3s;
  animation: slide 0.4s forwards;
  animation-delay: 0.3s;
}

.picture {
  height: 30px;
}

@-o-keyframes slide {
  100% {
    margin-left: 0%;
    opacity: 1;
  }
}

@-ms-keyframes slide {
  100% {
    margin-left: 0%;
    opacity: 1;
  }
}

@-moz-keyframes slide {
  100% {
    margin-left: 0%;
    opacity: 1;
  }
}

@-webkit-keyframes slide {
  100% {
    margin-left: 0%;
    opacity: 1;
  }
}

@keyframes slide {
  100% {
    margin-left: 0%;
    opacity: 1;
  }
}

@-webkit-keyframes slide2 {
  100% {
    margin-left: 5%;
    opacity: 1;
  }
}

@keyframes slide2 {
  100% {
    margin-left: 5%;
    opacity: 1;
  }
}

@-webkit-keyframes slide3 {
  100% {
    margin-left: 10%;
    opacity: 1;
  }
}

@keyframes slide3 {
  100% {
    margin-left: 10%;
    opacity: 1;
  }
}

@-webkit-keyframes slide4 {
  100% {
    margin-left: 15%;
    opacity: 1;
  }
}

@keyframes slide4 {
  100% {
    margin-left: 15%;
    opacity: 1;
  }
}
