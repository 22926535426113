.MainContainer {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  min-height: 800px;
  min-width: 1300px;
}

.NameContainer {
  display: flex;
  align-self: center;
  flex-direction: column;
  margin-top: 4%;
  width: 50%;
}

.Title {
  position: static;
  -webkit-animation: slide 0.5s forwards;
  animation: slide 0.5s forwards;
}

.Bar {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.BodyContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow-y: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.BodyContainer::-webkit-scrollbar {
  display: none;
}

.GalleryContainer {
  display: flex;
  flex-direction: column;
}

.Seperator {
  margin-top: 500px;
}

.CardContainer1,
.CardContainer2,
.CardContainer3 {
  -webkit-animation: slide2 0.4s forwards;
  animation: slide2 0.4s forwards;
}

.CardContainer1 {
  -webkit-animation-delay: 1.4s;
  animation-delay: 1.4s;
}

.CardContainer2 {
  -webkit-animation-delay: 1.6s;
  animation-delay: 1.6s;
}

.CardContainer3 {
  -webkit-animation-delay: 1.8s;
  animation-delay: 1.8s;
}

.CardContentsContainer {
  display: flex;
  width: 100%;
  margin-bottom: 10px;
  flex-direction: row;
}

.CardTextContainer {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  width: 100%;
}

.TechContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.Tech {
  margin: 2px;
  padding: 5px;
  color: white;
  font-weight: 400;
  font-family: Calibri;
  background-color: rgba(0, 131, 148, 0.712);
  border-radius: 5px;
}

.LinkContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  margin-left: 20px;
}

.Link {
  margin-right: 20px;
}

.Logo {
  height: 30px;
}

.PictureContainer1,
.PictureContainer2,
.PictureContainer3 {
  text-decoration: none;
  margin-top: 2%;
}

.Picture1,
.Picture2,
.Picture3 {
  display: flex;
  align-self: center;
  margin-top: 20px;
  height: 100%;
  border-radius: 0px;
}

.Picture1 {
  -webkit-animation-delay: 1.4s;
  animation-delay: 1.4s;
}

.Picture2 {
  -webkit-animation-delay: 1.6s;
  animation-delay: 1.6s;
}

.Picture3 {
  -webkit-animation-delay: 1.8s;
  animation-delay: 1.8s;
}

.ProjectTitle1,
.ProjectTitle2,
.ProjectTitle3 {
  margin-bottom: 0px;
  color: white;
  font-size: 2.7vh;
  font-weight: 500;
  font-family: Calibri;
  overflow-wrap: break-word;
}

.ProjectDescription1,
.ProjectDescription2,
.ProjectDescription3 {
  overflow-wrap: break-word;
  color: white;
  font-weight: 300;
  font-family: Calibri;
}

.HeaderContainer {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 97%;
  height: 20px;
  margin-top: 30px;
}

.HeaderPicturePhoneOnly {
  position: fixed;
  height: 0px;
  width: 0px;
}

.NavContainer {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.Header {
  width: 600px;
}

@media (max-width: 500px) {
  .NameContainer {
    align-self: flex-start;
    width: 100vw;
  }

  .StackandTechnologies {
    font-weight: 300;
    font-family: Calibri;
    margin-bottom: 10px;
    color: white;
    font-size: 16px;
  }

  .Tech {
    font-size: 13px;
  }

  .BodyContainer {
    width: 100vw;
    overflow-x: hidden;
  }

  .GalleryContainer {
    width: 90%;
  }

  .LinkContainer {
    margin-left: 0px;
    margin-bottom: 0px;
    padding-right: 0px;
  }

  .Picture3 {
    -webkit-animation-delay: 1.8s;
    animation-delay: 1.8s;
  }

  .PictureContainer1,
  .PictureContainer2,
  .PictureContainer3 {
    text-decoration: none;
    margin-top: 10%;
  }

  .Seperator {
    margin-top: 350px;
  }

  .HeaderContainer {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .Header {
    display: flex;
    align-items: center;
  }

  .HeaderPicturePhoneOnly {
    position: absolute;
    margin-left: 10px;
    height: 40px;
    width: 40px;
    border-radius: 20px;
  }
}

@-o-keyframes slide {
  100% {
    margin-left: 2%;
    opacity: 1;
  }
}

@-ms-keyframes slide {
  100% {
    margin-left: 2%;
    opacity: 1;
  }
}

@-moz-keyframes slide {
  100% {
    margin-left: 2%;
    opacity: 1;
  }
}

@-webkit-keyframes slide {
  100% {
    margin-left: 2%;
    opacity: 1;
  }
}

@keyframes slide {
  100% {
    margin-left: 2%;
    opacity: 1;
  }
}

@-o-keyframes slide2 {
  100% {
    margin-top: 0%;
    opacity: 1;
  }
}

@-ms-keyframes slide2 {
  100% {
    margin-top: 0%;
    opacity: 1;
  }
}

@-moz-keyframes slide2 {
  100% {
    margin-top: 0%;
    opacity: 1;
  }
}

@-webkit-keyframes slide2 {
  100% {
    margin-top: 0%;
    opacity: 1;
  }
}

@keyframes slide2 {
  100% {
    margin-top: 0%;
    opacity: 1;
  }
}
