html {
  height: 100%;
  background: black;
}
body {
  margin: 0;
  min-height: 100%;
  overflow: hidden;
  background: rgb(0, 18, 20);
}
