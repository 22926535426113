.HeaderContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 20px;
  width: 100%;
}

.BarItem1,
.BarItem2,
.BarItem3,
.BarItem4,
.BarItem5 {
  display: flex;
  align-items: center;
  color: white;
  text-decoration: none;
  height: 20px;
  opacity: 0;
}

h3 {
  font-family: Calibri;
  font-weight: 300;
}

.BarItem1 {
  -webkit-animation: slide 0.4s forwards;
  animation: slide 0.4s forwards;
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.BarItem2 {
  -webkit-animation: slide 0.4s forwards;
  animation: slide 0.4s forwards;
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}

.BarItem3 {
  -webkit-animation: slide 0.4s forwards;
  animation: slide 0.4s forwards;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.BarItem4 {
  -webkit-animation: slide 0.4s forwards;
  animation: slide 0.4s forwards;
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}

.BarItem5 {
  -webkit-animation: slide 0.4s forwards;
  animation: slide 0.4s forwards;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.underlineEffect1,
.underlineEffect2,
.underlineEffect3,
.underlineEffect4,
.underlineEffect5 {
  background: white;
  height: 2px;
  width: 0;
  -moz-animation: showHide 0.4s ease-in; /* Firefox */
  -webkit-animation: showHide 0.4s ease-in; /* Safari and Chrome */
  -ms-animation: showHide 0.4s ease-in; /* IE10 */
  -o-animation: showHide 0.4s ease-in; /* Opera */
  animation: showHide 0.4s ease-in;
}

.underlineEffect1 {
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
}

.underlineEffect2 {
  -webkit-animation-delay: 1.25s;
  animation-delay: 1.25s;
}

.underlineEffect3 {
  -webkit-animation-delay: 1.3s;
  animation-delay: 1.3s;
}

.underlineEffect4 {
  -webkit-animation-delay: 1.35s;
  animation-delay: 1.35s;
}

.underlineEffect5 {
  -webkit-animation-delay: 1.4s;
  animation-delay: 1.4s;
}

h3:hover .underlineEffect1,
h3:hover .underlineEffect2,
h3:hover .underlineEffect3,
h3:hover .underlineEffect4,
h3:hover .underlineEffect5 {
  width: 100%;
}

@-o-keyframes slide {
  100% {
    opacity: 1;
  }
}

@-ms-keyframes slide {
  100% {
    opacity: 1;
  }
}

@-moz-keyframes slide {
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes slide {
  100% {
    opacity: 1;
  }
}

@keyframes slide {
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes showHide {
  /* Chrome, Safari */
  0% {
    width: 0%;
  }
  25% {
    width: 100%;
  }
  75% {
    width: 100%;
  }
  100% {
    width: 0%;
  }
}
@-moz-keyframes showHide {
  /* FF */
  0% {
    width: 0%;
  }
  25% {
    width: 100%;
  }
  75% {
    width: 100%;
  }
  100% {
    width: 0%;
  }
}
@-ms-keyframes showHide {
  /* IE10 */
  0% {
    width: 0%;
  }
  25% {
    width: 100%;
  }
  75% {
    width: 100%;
  }
  100% {
    width: 0%;
  }
}
@-o-keyframes showHide {
  /* Opera */
  0% {
    width: 0%;
  }
  25% {
    width: 100%;
  }
  75% {
    width: 100%;
  }
  100% {
    width: 0%;
  }
}
@keyframes showHide {
  0% {
    width: 0%;
  }
  25% {
    width: 100%;
  }
  75% {
    width: 100%;
  }
  100% {
    width: 0%;
  }
}
