.Bar {
  background-color: white;
  opacity: 0;
  width: 0px;
  height: 1px;
  -o-animation: widthExpand 0.5s forwards;
  -ms-animation: widthExpand 0.5s forwards;
  -moz-animation: widthExpand 0.5s forwards;
  -webkit-animation: widthExpand 0.5s forwards;
  animation: widthExpand 0.5s forwards;
}

@-o-keyframes widthExpand {
  100% {
    width: 100%;
    opacity: 1;
  }
}

@-ms-keyframes widthExpand {
  100% {
    width: 100%;
    opacity: 1;
  }
}

@-moz-keyframes widthExpand {
  100% {
    width: 100%;
    opacity: 1;
  }
}

@-webkit-keyframes widthExpand {
  100% {
    width: 100%;
    opacity: 1;
  }
}

@keyframes widthExpand {
  100% {
    width: 100%;
    opacity: 1;
  }
}
