.MainText {
  color: white;
  position: absolute;
  font-family: Calibri;
  opacity: 0;
  margin-left: -10%;
  font-weight: 300;
  -o-animation: slide 0.5s forwards;
  -ms-animation: slide 0.5s forwards;
  -moz-animation: slide 0.5s forwards;
  -webkit-animation: slide 0.5s forwards;
  animation: slide 0.5s forwards;
}

@-o-keyframes slide {
  100% {
    margin-left: 0%;
    opacity: 1;
  }
}

@-ms-keyframes slide {
  100% {
    margin-left: 0%;
    opacity: 1;
  }
}

@-moz-keyframes slide {
  100% {
    margin-left: 0%;
    opacity: 1;
  }
}

@-webkit-keyframes slide {
  100% {
    margin-left: 0%;
    opacity: 1;
  }
}

@keyframes slide {
  100% {
    margin-left: 0%;
    opacity: 1;
  }
}
