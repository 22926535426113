.NonDrawerContainer {
  display: flex;
  justify-self: flex-end;
  flex-direction: column;
  position: absolute;
  left: 0;
  height: 100%;
  width: 40%;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0);
}
.DrawerContainer {
  display: flex;
  justify-self: flex-end;
  flex-direction: column;
  position: absolute;
  align-items: center;
  margin-top: 80px;
  right: 0;
  height: 100%;
  width: calc(100% - 1px);
  z-index: 1;
  margin-right: -100%;
  background-color: rgb(0, 0, 0);
  border-top: 1px solid white;
  border-left: 1px solid white;
  -o-animation: slide 0.3s forwards;
  -ms-animation: slide 0.3s forwards;
  -moz-animation: slide 0.3s forwards;
  -webkit-animation: slide 0.3s forwards;
  animation: slide 0.3s forwards;
}

.BarItem1,
.BarItem2,
.BarItem3,
.BarItem4,
.BarItem5 {
  display: flex;
  align-items: center;
  text-align: center;
  color: white;
  width: 100%;
  text-decoration: none;
  border-bottom: 1px solid white;
  height: 60px;
}

.TextItem1,
.TextItem2,
.TextItem3,
.TextItem4,
.TextItem5 {
  padding-left: 10px;
}

.FirstBar,
.SecondBar,
.ThirdBar {
  height: 2px;
  width: 20px;
  background-color: white;
}

@-o-keyframes slide {
  100% {
    margin-right: 0%;
  }
}

@-ms-keyframes slide {
  100% {
    margin-right: 0%;
  }
}

@-moz-keyframes slide {
  100% {
    margin-right: 0%;
  }
}

@-webkit-keyframes slide {
  100% {
    margin-right: 0%;
  }
}

@keyframes slide {
  100% {
    margin-right: 0%;
  }
}
