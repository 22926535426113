.Picture {
  color: white;
  opacity: 0;
  margin-top: -5%;
  -o-animation: slide 0.5s forwards;
  -ms-animation: slide 0.5s forwards;
  -moz-animation: slide 0.5s forwards;
  -webkit-animation: slide 0.5s forwards;
  animation: slide 0.5s forwards;
}

@-o-keyframes slide {
  100% {
    margin-top: 0%;
    opacity: 1;
  }
}

@-ms-keyframes slide {
  100% {
    margin-top: 0%;
    opacity: 1;
  }
}

@-moz-keyframes slide {
  100% {
    margin-top: 0%;
    opacity: 1;
  }
}

@-webkit-keyframes slide {
  100% {
    margin-top: 0%;
    opacity: 1;
  }
}

@keyframes slide {
  100% {
    margin-top: 0%;
    opacity: 1;
  }
}
