.MainContainer {
  width: 100vw;
  height: 100vh;
  min-height: 600px;
  min-width: 600px;
}

.BodyContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 80%;
  margin-left: 20%;
}

.NameContainer {
  display: flex;
  height: 250px;
  width: 500px;
}

.Name {
  display: flex;
  opacity: 1;
  height: 33px;
  margin-left: 0%;
  align-items: center;
  color: rgb(0, 163, 163);
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  border-right: 0.08em solid rgb(255, 255, 255);
  -moz-animation: typing 1.2s steps(22, end), blink-caret 1.1s step-end 2 1.7s;
  animation: typing 1.2s steps(22, end), blink-caret 1.1s step-end 2 1.7s;
  animation-fill-mode: forwards;
}

.Title {
  margin-top: 60px;
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

/* The typing effect */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 228px;
  }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: rgb(255, 255, 255);
  }
}

.School {
  margin-top: 120px;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.HeaderContainer {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 97%;
  height: 20px;
  margin-top: 30px;
}

.HeaderPicturePhoneOnly {
  position: fixed;
  height: 0px;
  width: 0px;
}

.NavContainer {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.Header {
  width: 600px;
}

.FooterContainer {
  width: 500px;
}

@media (max-width: 800px) {
  .BodyContainer {
    height: 60%;
    margin-left: 2%;
  }

  .NameContainer {
    height: 250px;
    width: 400px;
  }

  .HeaderContainer {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .Header {
    display: flex;
    align-items: center;
  }

  .HeaderPicturePhoneOnly {
    position: absolute;
    margin-left: 10px;
    height: 40px;
    width: 40px;
    border-radius: 20px;
  }

  .FooterContainer {
    width: 400px;
  }

  /* The typing effect */
  @keyframes typing {
    from {
      width: 0;
    }
    to {
      width: 236px;
    }
  }
}
