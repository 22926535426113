.HeaderContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.BarItem1,
.BarItem2,
.BarItem3,
.BarItem4,
.BarItem5 {
  display: flex;
  align-items: center;
  color: white;
  text-decoration: none;
  height: 20px;
}

h3 {
  font-family: Calibri;
  font-weight: 300;
}

.underlineEffect1,
.underlineEffect2,
.underlineEffect3,
.underlineEffect4,
.underlineEffect5 {
  height: 2px;
  opacity: 0;
  background: white;
}

h3:hover .underlineEffect1,
h3:hover .underlineEffect2,
h3:hover .underlineEffect3,
h3:hover .underlineEffect4,
h3:hover .underlineEffect5 {
  opacity: 1;
}
