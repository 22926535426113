.ButtonContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 20px;
}

.FirstBar,
.SecondBar,
.ThirdBar {
  height: 2px;
  width: 20px;
  background-color: white;
}