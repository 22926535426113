.MainContainer {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  min-height: 900px;
  min-width: 900px;
}

.NameContainer {
  position: static;
  display: flex;
  align-self: center;
  flex-direction: column;
  margin-top: 6%;
  width: 50%;
}

.Title {
  position: static;
  -webkit-animation: slide 0.5s forwards;
  animation: slide 0.5s forwards;
}

.BodyContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow-y: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.BodyContainer::-webkit-scrollbar {
  display: none;
}

* {
  scrollbar-width: none;
}

.Bar {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.GalleryContainer {
  position: absolute;
  display: flex;
  height: 70%;
  margin-top: 20px;
  overflow-y: scroll;
}

.Column1,
.Column2,
.Column3,
.Column4,
.Column5 {
  display: flex;
  flex-direction: column;
}

.Picture1,
.Picture2,
.Picture3,
.Picture4,
.Picture5,
.Picture6,
.Picture7,
.Picture8,
.Picture9 {
  display: flex;
  -webkit-animation: slide2 0.4s forwards;
  animation: slide2 0.4s forwards;
}

.Picture1 {
  -webkit-animation-delay: 1.4s;
  animation-delay: 1.4s;
}

.Picture2 {
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
}

.Picture3 {
  -webkit-animation-delay: 1.6s;
  animation-delay: 1.6s;
}

.Picture4 {
  -webkit-animation-delay: 1.7s;
  animation-delay: 1.7s;
}

.Picture5 {
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
}

.Picture6 {
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
}

.Picture7 {
  -webkit-animation-delay: 1.7s;
  animation-delay: 1.7s;
}

.Picture8 {
  -webkit-animation-delay: 1.8s;
  animation-delay: 1.8s;
}

.Picture9 {
  -webkit-animation-delay: 1.85s;
  animation-delay: 1.85s;
}

.HeaderContainer {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 97%;
  height: 20px;
  margin-top: 30px;
}

.HeaderPicturePhoneOnly {
  position: fixed;
  height: 0px;
  width: 0px;
}

.NavContainer {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.Header {
  width: 600px;
}

@media (max-width: 500px) {
  .NameContainer {
    align-self: flex-start;
    width: 100vw;
  }

  .BodyContainer {
    width: 102vw;
  }

  .GalleryContainer {
    width: 102vw;
    height: 100%;
    margin-top: 0px;
  }

  .Picture3 {
    width: 200px;
    -webkit-animation-delay: 1.8s;
    animation-delay: 1.8s;
  }

  .Seperator {
    margin-top: 350px;
  }

  .HeaderContainer {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .Header {
    display: flex;
    align-items: center;
  }

  .HeaderPicturePhoneOnly {
    position: absolute;
    margin-left: 10px;
    height: 40px;
    width: 40px;
    border-radius: 20px;
  }
}

@-o-keyframes slide {
  100% {
    margin-left: 2%;
    opacity: 1;
  }
}

@-ms-keyframes slide {
  100% {
    margin-left: 2%;
    opacity: 1;
  }
}

@-moz-keyframes slide {
  100% {
    margin-left: 2%;
    opacity: 1;
  }
}

@-webkit-keyframes slide {
  100% {
    margin-left: 2%;
    opacity: 1;
  }
}

@keyframes slide {
  100% {
    margin-left: 2%;
    opacity: 1;
  }
}

@-o-keyframes slide2 {
  100% {
    margin-top: 0%;
    opacity: 1;
  }
}

@-ms-keyframes slide2 {
  100% {
    margin-top: 0%;
    opacity: 1;
  }
}

@-moz-keyframes slide2 {
  100% {
    margin-top: 0%;
    opacity: 1;
  }
}

@-webkit-keyframes slide2 {
  100% {
    margin-top: 0%;
    opacity: 1;
  }
}

@keyframes slide2 {
  100% {
    margin-top: 0%;
    opacity: 1;
  }
}
