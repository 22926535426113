.CardContainer {
  display: flex;
  flex-direction: column;
  opacity: 0;
  box-shadow: 7px 7px 5px #ffffff42;
  border: 1px solid white;
  border-radius: 10px;
  -o-animation: slide 0.5s forwards;
  -ms-animation: slide 0.5s forwards;
  -moz-animation: slide 0.5s forwards;
  -webkit-animation: slide 0.5s forwards;
  animation: slide 0.5s forwards;
}

.Picture {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

@-o-keyframes slide {
  100% {
    opacity: 1;
  }
}

@-ms-keyframes slide {
  100% {
    opacity: 1;
  }
}

@-moz-keyframes slide {
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes slide {
  100% {
    opacity: 1;
  }
}

@keyframes slide {
  100% {
    opacity: 1;
  }
}
